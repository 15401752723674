import * as React from "react"
import {
  ChakraProvider,
  Box,
  VStack,
  theme,
  Image,
} from "@chakra-ui/react"
import somethingisbrewing from "./somethingisbrewing.png"

export const App = () => {

return (
  <ChakraProvider theme={theme}>
    <Box>
      <VStack minH="100vh" justify="center">
        <Image  src={somethingisbrewing} alt='HackerDAO' />
      </VStack>
    </Box>
  </ChakraProvider>
)
}